export default {
  config: {},
  deviceTypeLayout: null,
  gameState: {},
  gameChannelType: 'Web',
  gameLayout: 'Expanded',
  minBallSelection: 6,
  maxBallSelection: 10,
  maxOutcomeSelection: 4,
  edgeCaseSelection: 3,
  firstBallColorAllowed: [1, 2, 4],
  specialBetIds: [5, 6, 7, 8, 11],
  bingoColors: ['#dd1f1f', '#1cc51c', '#0087ff', '#a82def', '#844e14', '#efc82d', '#cb5b00', '#9a9a9a'],
  isAudioOn: false,
  isPaymentInProgress: false,
  minBingoNumber: 1,
  maxBingoNumber: 48,
  selectedBetNumbers: [],
  numbersBet: {},
  colorBet: {},
  selectedColor: {},
  selectedSpecialBet: {},
  firstBallColorBet: {},
  preballBet: {},
  selectedBet: {},
  showInfoModal: false,
  roundId: null,
  visualizationPlugin: {
    origin: {
      development: 'https://dev.draw-visualization.7platform.net/luckysix',
      staging: 'https://staging.draw-visualization.7platform.net/luckysix',
      production: 'https://draw-visualization.7platform.net/luckysix',
    },
    type: 'v2', // move to games-static
    clientType: 'user',
    clientSubType: 'Player',
    encoding: {
      default: 'plaintext',
    },
  },
  oddsType: 'decimal', // move to games-static
  sound: true, // move to games-static
  showBackButton: true, // move to games-static
  fullScreenEnabled: true, // move to games-static
  ticketCancelAllowed: true, // move to games-static
  appMode: 'standalone', // move to games-static
  currentDrawnBall: null,
  gcmApi: {
    staging: [
      'https://gcm-fra-staging-1.7platform.com:8008',
      'https://gcm-fra-staging-2.7platform.com:8008',
    ],
    production: [
      'https://gcm-fra-1.7platform.com:8008',
      'https://gcm-fra-2.7platform.com:8008',
      'https://gcm-fra-3.7platform.com:8008',
    ],
  },
  isBettingDisabled: false,
  player: {
    username: '',
    id: '',
    loggedIn: false,
    auth: {
      token: 'xx',
    },
    balance: {
      time: null,
      balanceType: '',
      balanceAmt: 0,
      currency: '',
      divide: 1,
      locale: 'en-EN',
    },
    audio: false,
  },
  preball: false,
  stats: {
    drawnMostTimes: [
      {
        count: 100,
        ball: 1,
      },
      {
        count: 100,
        ball: 28,
      },
      {
        count: 100,
        ball: 22,
      },
      {
        count: 100,
        ball: 23,
      },
      {
        count: 100,
        ball: 24,
      },
    ],
    drawnLeastTimes: [
      {
        count: 0,
        ball: 48,
      },
      {
        count: 0,
        ball: 36,
      },
      {
        count: 0,
        ball: 37,
      },
      {
        count: 0,
        ball: 39,
      },
      {
        count: 0,
        ball: 40,
      },
    ],
    firstBallOddEven: {
      even: '',
      odd: '',
    },
    firstBallLowHigh: {
      low: '',
      high: '',
    },
    preballsMoreOddEven: {
      even: '',
      odd: '',
    },
    preballsSumLowHigh: {
      low: '',
      high: '',
    },
    firstBallColor: {
      red: 100,
      green: 0,
      blue: 0,
      purple: 0,
      brown: 0,
      yellow: 0,
      orange: 0,
      black: 0,
    },
  },
  resultsData: [],
  lastTicketsData: [],
  ticketHistoryData: [],
  showModalStats: false,
  lastTicketsInProgress: false,
  ticketHistoryInProgress: false,
  ticketHistoryRangeError: {},
  isFullScreen: false,
  localizedTranslations: {},
  showNewFeatures: true,
  showFeed: false,
  thirdPartyToken: null,
  isTouchDevice: false,
  isBetslipOpen: true,
  jackpotState: undefined,
  isonisList: ['MNE'],
  showBonusModal: false,
  isFreeBetMode: false,
  isTerminal: false,
  countdownData: null,
  terminalUserData: null,
  activeTerminalLayout: null,
  ticketHistoryTerminal: [],
  ticketDetailsData: null,
  loaded: false,
  combinationsBase: 6,
  terminalMouseCursorEnabled: true,
  activeComponent: null,
  ticketToRebet: null,
  userIsOperator: false,
};
